import React from 'react'

const Banner = (props) => {
  return (
    <section id="banner" className="major">
      <div className="inner">
        <header className="major">
          <h1 className="hero">Coleman School of Dance</h1>
          <h2>New Rochelle, New York</h2>
        </header>

        <div className="content">
          <ul className="actions">
            <li>
              <a href="#one" className="button next scrolly">
                Learn More
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Banner
