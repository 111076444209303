import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <header className="major">
        <h1>{children}</h1>
      </header>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { gatsbyImageData } = node.data.target
      if (!gatsbyImageData) {
        // asset is not an image
        return null
      }
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <GatsbyImage image={gatsbyImageData} />
        </div>
      )
    },
  },
}

const RichText = ({ data }) => {
  const { body } = data.contentfulHomePage

  return <div className="inner">{body && renderRichText(body, options)}</div>
}

export default RichText
